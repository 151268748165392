import {
  ApplicationRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { CustomizationAreaComponent } from 'src/app/components/customization-area/customization-area.component';
import { Cap } from 'src/app/models/cap';
import { Quantities } from 'src/app/models/quantities';
import { Text } from 'src/app/models/text';
import { ThresholdColor } from 'src/app/models/thresholdColor';
import { WaterBottle } from 'src/app/models/waterBottle';
import { EventService } from 'src/app/services/event.service';
import { MainService } from 'src/app/services/main.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { Utils } from 'src/app/services/utils';

export class AreaTexture {
  blob: Blob;
  index: number;
}

export class ConfiguratorStatus {
  id = 0;
  areas = {};
  bottleColor: ThresholdColor;
  capColor: ThresholdColor;
  valveColor: ThresholdColor;
  userQuantity: Quantities;
  textures?: Array<AreaTexture>;
}

@Component({
  selector: 'app-configurator-page',
  templateUrl: './configurator-page.component.html',
  styleUrls: ['./configurator-page.component.scss']
})
export class ConfiguratorPageComponent implements OnInit, OnChanges, OnDestroy {

  @Input () step;
  @Input() cap: Cap;
  @Input() bottle: WaterBottle;
  @Input() variants: Array<WaterBottle>;
  @Input() selectedVariant: number;
  @Output() quantity = new EventEmitter<Quantities>();
  @Output() imagesBase64 = new EventEmitter<Array<any>>();
  @Output() bottleColorChanged = new EventEmitter<string>();
  @Output() capColorChanged = new EventEmitter<string>();
  @Output() valveColorChanged = new EventEmitter<string>();
  @Output() textureChanged = new EventEmitter<AreaTexture>();
  @Output() variantChanged = new EventEmitter<number>();
  userQuantity: Quantities;

  quantities: Array<Quantities>;

  @ViewChildren('customArea') customAreas: QueryList<CustomizationAreaComponent>;
  areas = {};
  images = [];
  textures?: Array<AreaTexture>;

  bottleColors?: Array<ThresholdColor>;
  bottleColorIndex = -1;
  bottleColor: ThresholdColor;

  capColors?: Array<ThresholdColor>;
  capColorIndex = -1;
  capColor: ThresholdColor;

  valveColors?: Array<ThresholdColor>;
  valveColorIndex = -1;
  valveColor: ThresholdColor;

  minQuantity = 1;
  minQuantityError = false;

  private langSub: Subscription;

  capName = '';
  valveName = '';
  queryParamsSub: any;
  description: SafeHtml;
  private serverTexts: Array<Text>;
  id: number;
  constructor(
    private sanitizer: DomSanitizer,
    private appRef: ApplicationRef,
    private userDataService: UserDataService,
    private utils: Utils,
    private eventService: EventService,
    private mainService: MainService,
    private route: ActivatedRoute,
    private translate: TranslateService) { }

  ngOnInit(): void {
    this.bottleColors = [];
    this.capColors = [];
    this.valveColors = [];
    this.queryParamsSub = this.route.queryParamMap.subscribe(params => {
      this.loadWaterBottle(parseInt(params.get('id'), 10));
    });
    this.langSub = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.updateDescription();
    });
  }

  ngOnDestroy() {
    this.queryParamsSub.unsubscribe();
    this.langSub.unsubscribe();
  }

  private loadWaterBottle(id: number) {
    this.mainService.getWaterBottle(id).then(response => {
      this.id = id;
      this.serverTexts = response.texts;
      this.updateDescription();
    }).catch(error => {
      this.eventService.hideSpinner();
    });
  }

  private updateDescription() {
    if (!this.serverTexts) {
      return;
    }
    let description = this.utils.getTextForKey(this.serverTexts, 'water_bottle', 'long_description');
    this.description = this.sanitizer.bypassSecurityTrustHtml(description);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.cap) {
      if (this.cap) {
        this.capName = this.translate.instant('color_cap', {cap: this.utils.getTextForKey(this.cap.texts, 'cap', 'name')});
        this.capName = this.capName.substr(0, 1).toUpperCase() + this.capName.substring(1).toLowerCase();
        this.valveName = this.translate.instant('color_valve', {valve: this.utils.getTextForKey(this.cap.texts, 'valve', 'name')});
        this.valveName = this.valveName.substr(0, 1).toUpperCase() + this.valveName.substring(1).toLowerCase();
      }
      this.quantityChanged();
    }

    if (changes.bottle) {
      this.quantityChanged();
    }

    if (changes.step) {
      if (changes.step.currentValue === 0 && this.customAreas !== undefined) {
        this.textures = [];
        this.customAreas.toArray().forEach((area, index) => {
          const areaTexture = new AreaTexture();
          areaTexture.blob = null;
          areaTexture.index = this.bottle.areas[index].mesh_index;
          this.textureChanged.emit(areaTexture);
        });
      } else if (changes.step.currentValue === 1) {
        setTimeout(() => {
          this.customAreas.toArray().forEach((value, index) => {
            value.setSize(this.bottle.areas[index].area_width, this.bottle.areas[index].area_height);
            value.renderBackgroundColor = this.bottleColor.color_hex;
            if (this.areas[index]) {
              value.areas = this.areas[index];
              value.refresh();
            }
            this.renderTextures();
          });
        }, 0);
      }
    }
  }

  onBottleColorSelected(index: number) {
    if (this.bottleColorIndex !== -1) {
      this.bottleColors[this.bottleColorIndex].selected = false;
    }
    this.bottleColorIndex = index;
    this.bottleColors[this.bottleColorIndex].selected = true;
    this.bottleColorChanged.emit(this.bottleColors[this.bottleColorIndex].color_hex);
    this.bottleColor = this.bottleColors[this.bottleColorIndex];
  }

  onCapColorSelected(index: number) {
    if (this.capColorIndex !== -1) {
      this.capColors[this.capColorIndex].selected = false;
    }
    this.capColorIndex = index;
    this.capColors[this.capColorIndex].selected = true;
    this.capColorChanged.emit(this.capColors[this.capColorIndex].color_hex);
    this.capColor = this.capColors[this.capColorIndex];
  }

  onValveColorSelected(index: number) {
    if (this.valveColorIndex !== -1) {
      this.valveColors[this.valveColorIndex].selected = false;
    }
    this.valveColorIndex = index;
    this.valveColors[this.valveColorIndex].selected = true;
    this.valveColorChanged.emit(this.valveColors[this.valveColorIndex].color_hex);
    this.valveColor = this.valveColors[this.valveColorIndex];
  }

  quantityChanged() {
    let quantity = this.userQuantity;
    if (this.bottle) {
      this.quantities = this.bottle.quantities;
      let found = false;
      if (quantity) {
        this.bottle.quantities.forEach(item => {
          if (item.quantity === quantity.quantity) {
            found = true;
            quantity = item;
            this.userQuantity = quantity;
          }
        });
      }
      if (!found) {
        quantity = this.quantities[0];
        this.userQuantity = quantity;
      }
      const hex = this.bottleColor ? this.bottleColor.color_hex : '';
      this.bottleColors = [];
      const colors = this.bottle.thresholds_colors.filter(element => {
        return element.type === 'water_bottle';
      });
      colors.forEach(color => {
        if (color.threshold_from <= quantity.quantity && (color.threshold_to >= quantity.quantity || color.threshold_to === 0)) {
          const tmp = this.bottleColors.filter(otherColor => {
            return otherColor.color_hex === color.color_hex;
          });
          if (tmp.length === 0) {
            this.bottleColors.push(color);
          }
        }
      });

      if (this.bottleColors.length > 0) {
        this.bottleColors.forEach(color => {
          color.selected = false;
        });
        this.bottleColorIndex = 0;
        this.bottleColors[0].selected = true;
        this.bottleColor = this.bottleColors[0];

        this.bottleColors.forEach((color, index) => {
          if (color.color_hex === hex) {
            this.bottleColorIndex = index;
            this.bottleColors[0].selected = false;
            color.selected = true;
            this.bottleColor = color;
          }
        });
        if (hex !== this.bottleColors[this.bottleColorIndex].color_hex) {
          this.bottleColorChanged.emit(this.bottleColors[this.bottleColorIndex].color_hex);
        }
      } else {
        this.bottleColorIndex = -1;
        this.bottleColor = null;
      }
    }

    if (this.cap) {
      // cap
      let hex = this.capColor ? this.capColor.color_hex : '';
      this.capColors = [];
      let colors = this.cap.thresholds_colors.filter(element => {
        return element.type === 'cap';
      });
      colors.forEach(color => {
        if (color.threshold_from <= quantity.quantity && (color.threshold_to >= quantity.quantity || color.threshold_to === 0)) {
          const tmp = this.capColors.filter(otherColor => {
            return otherColor.color_hex === color.color_hex;
          });
          if (tmp.length === 0) {
            this.capColors.push(color);
          }
        }
      });
      if (this.capColors.length > 0) {
        this.capColors.forEach(color => {
          color.selected = false;
        });
        this.capColorIndex = 0;
        this.capColors[0].selected = true;
        this.capColor = this.capColors[0];

        this.capColors.forEach((color, index) => {
          if (color.color_hex === hex) {
            this.capColorIndex = index;
            this.capColors[0].selected = false;
            color.selected = true;
            this.capColor = color;
          }
        });
        if (hex !== this.capColors[this.capColorIndex].color_hex) {
          this.capColorChanged.emit(this.capColors[this.capColorIndex].color_hex);
        }
      } else {
        this.capColorIndex = -1;
        this.capColor = null;
      }

      // valve
      hex = this.valveColor ? this.valveColor.color_hex : '';
      this.valveColors = [];
      colors = this.cap.thresholds_colors.filter(element => {
        return element.type === 'valve';
      });
      colors.forEach(color => {
        if (color.threshold_from <= quantity.quantity && (color.threshold_to >= quantity.quantity || color.threshold_to === 0)) {
          const tmp = this.valveColors.filter(otherColor => {
            return otherColor.color_hex === color.color_hex;
          });
          if (tmp.length === 0) {
            this.valveColors.push(color);
          }
        }
      });
      if (this.valveColors.length > 0) {
        this.valveColors.forEach(color => {
          color.selected = false;
        });
        this.valveColorIndex = 0;
        this.valveColors[0].selected = true;
        this.valveColor = this.valveColors[0];

        this.valveColors.forEach((color, index) => {
          if (color.color_hex === hex) {
            this.valveColorIndex = index;
            this.valveColors[0].selected = false;
            color.selected = true;
            this.valveColor = color;
          }
        });
        if (hex !== this.valveColors[this.valveColorIndex].color_hex) {
          this.valveColorChanged.emit(this.valveColors[this.valveColorIndex].color_hex);
        }
      } else {
        this.valveColorIndex = -1;
        this.valveColor = null;
      }
    }
  }

  variantSelected(id: number) {
    if (id !== this.selectedVariant) {
      this.variantChanged.emit(id);
    }
  }

  checkStep() {
    if (this.step === 0) {
      this.minQuantityError = this.userQuantity.quantity < this.minQuantity;
      return !this.minQuantityError;
    }
    return true;
  }

  save() {
    if (this.step === 1) {
      this.areas = {};
      this.customAreas.toArray().forEach((value, index) => {
        this.areas[index] = value.areas;
      });
    }
    this.quantity.emit(this.userQuantity);
  }

  checkAreas(): boolean {
    let ok = true;
    this.customAreas.forEach(area => {
      if (area.areas.length === 0) {
        ok = false;
      }
    });
    return ok;
  }

  generateImages(callback: () => void) {
    this.images = [];
    this.generateImageAtIndex(callback, 0);
  }

  private generateImageAtIndex(callback: () => void, index: number) {
    const areas = this.customAreas.toArray();
    if (index >= areas.length) {
      callback();
    } else {
      areas[index].renderImage(false, 300, blob => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result;
          const src = '' + base64data;
          const obj = {
            srcImage: src,
            area_index: index + 1,
            height: (150 * areas[index].height) / areas[index].width
          };
          this.images.push(obj);
          this.appRef.tick();
          this.imagesBase64.emit(this.images);
          this.generateImageAtIndex(callback, index + 1);
        };
      });
    }
  }

  renderTextures() {
    this.textures = [];
    this.customAreas.toArray().forEach((area, index) => {
      area.renderImage(true, 300, blob => {
        const areaTexture = new AreaTexture();
        areaTexture.blob = blob;
        areaTexture.index = this.bottle.areas[index].mesh_index;
        this.textureChanged.emit(areaTexture);
        this.textures.push(areaTexture);
      });
    });
  }

  onAreaChanged(event: any) {
    this.renderTextures();
  }

  saveStatus() {
    const status = new ConfiguratorStatus();
    status.id = this.id;
    status.areas = this.areas;
    status.bottleColor = this.bottleColor;
    status.capColor = this.capColor;
    status.valveColor = this.valveColor;
    status.userQuantity = this.userQuantity;
    status.textures = this.textures;
    this.userDataService.configuratorStatus = status;
  }

  loadStatus() {
    const status = this.userDataService.configuratorStatus;
    this.id = status.id;
    this.areas = status.areas;
    this.bottleColor = status.bottleColor;
    this.capColor = status.capColor;
    this.valveColor = status.valveColor;
    this.userQuantity = status.userQuantity;
    this.bottleColorChanged.emit(this.bottleColor.color_hex);
    this.capColorChanged.emit(this.capColor.color_hex);
    this.valveColorChanged.emit(this.valveColor.color_hex);
    this.quantityChanged();
  }

  getColorName(color: ThresholdColor): string {
    return this.utils.mapColorName(color.color_hex, color.color_name);
  }

  getOrderPrice(): number {
    return Math.round(this.userQuantity.price * this.userQuantity.quantity * 100) / 100;
  }

  showDisclaimer() {
    this.eventService.setTextPopupText(this.translate.instant('area_superior_text_disclaimer_popup'));
    this.eventService.showTextPopup();
  }
}
